<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="text-center">
        <div class="primary--text text-md-h5 text-uppercase">Blog</div>
        <base-divider :color="'primary'"/>
        <span>
          Naš je blog, prije svega, edukativan, afirmativan, ali i kritičan prema brojnim procesima u društvu i sportskim organizacijama. Tako afirmiramo vrijednosti i kritiziramo društveno štetne pojavnosti.
        </span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4" v-for="(item, i) in blogs" :key="i">
        <v-card height="100%" style="height: 100% !important">
<!--          <v-img height="160" :src="require(`@/assets/img/${item.image}`)"></v-img>-->
          <v-img height="160" :src="'https://protomic.hr/admin/api/storage/' + item.image"></v-img>
          <v-card-title class="primary--text">
            {{ item.title }}
          </v-card-title>
          <v-card-subtitle class="secondary--text" v-if="item.subtitle">{{ item.subtitle }}</v-card-subtitle>
          <v-card-text></v-card-text>
          <div class="px-4" v-html="item.content.slice(0, 250) + '...'"></div>
          <v-card-actions>
            <span class="grey--text subtitle-2 ml-3" >Ivan Tomić</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined class="text-none" :to="'/blog/' + item.alias">Pročitaj više</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseDivider from "@/layouts/Divider";
import axios from "axios";

export default {
  name: "Blogs",
  components: {BaseDivider},
  created() {
    this.getBlog()
  },
  methods: {
    getBlog() {
      let requestBody = {
        page: 1,
        postsPerPage: 3,
        alias: ['blog'],
        filters: {
          featured: 0,
          hide_home: false
        }
      };

      axios
          .post("https://protomic.hr/admin/api/web/objave", requestBody)
          .then(response => {
            this.blogs = response.data;
            // console.log(this.active_blog)
            // for (let i = 0; i < this.active_blog.images.length; i++) {
            //   this.lista_slika.push(
            //       "https://protomic.hr/admin/api/storage/" + this.active_blog.images[i]
            //   );
            // }
          })
          .catch(error => console.log(error.message));
    },
  },
  data: () => ({
    blogs: []
  })
}
</script>

<style scoped>

</style>