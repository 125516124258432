<template>
  <div
      :class="classes"
      class="pt-2"
  >
    <base-avatar
        v-if="icon"
        :color="color"
        :dark="dark"
        :icon="icon"
        :outlined="outlined"
        :size="size"
        class="mb-3"
    />

    <div :class="horizontal && title && 'ml-6'">
      <div class="font-weight-medium mb-4 text-uppercase">{{title}}</div>
      <div class="text--secondary">{{text}}</div>
    </div>
  </div>
</template>

<script>

import BaseAvatar from "@/layouts/Avatar";
export default {
  name: 'BaseAvatarCard',
  components: {BaseAvatar},
  props: {
    align: {
      type: String,
      default: 'left',
    },
    color: String,
    dark: Boolean,
    horizontal: Boolean,
    icon: String,
    outlined: {
      type: Boolean,
      default: true,
    },
    space: {
      type: [Number, String],
      default: 8,
    },
    size: {
      type: [Number, String],
      default: 72,
    },
    text: String,
    title: String,
  },

  computed: {
    classes () {
      const classes = [
        `mb-${this.space}`,
      ]

      if (this.horizontal) {
        classes.push('d-flex')

        if (!this.$slots.default && !this.text) {
          classes.push('align-center')
        }
      }

      return classes
    },
  },
}
</script>
