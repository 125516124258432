<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center">
          <div class="text-md-h5 font-weight-bold text-uppercase">O nama</div>
          <base-divider color="primary" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
            v-for="(feature, i) in features"
            :key="i"
            cols="12"
            md="6"
        >
          <base-avatar-card
              v-bind="feature"
              align="left"
              horizontal
          >
            {{feature.text}}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseDivider from "@/layouts/Divider";
import BaseAvatarCard from "@/layouts/AvatarCard";
export default {
  name: "About",
  components: {BaseAvatarCard, BaseDivider},
  data: () => ({
    features: [
      {
        color: 'primary',
        title: 'Nastanak',
        dark: true,
        icon: 'mdi-account-settings-outline',
        text: 'ProTomic sport je agencija za sportski menadžment. Nastala je uslijed potrebe za pružanjem\n' +
            'usluga strateškoga oblikovanja sportskih organizacija. Na temelju znanja, kao i uvida u funkcioniranje\n' +
            'europskih i svjetskih sportskih organizacija, nastojimo ukazati na važnost strateškoga upravljanja i\n' +
            'planiranja te strateškoga oblikovanja komunikacija.',
      },
      {
        color: 'primary',
        title: 'Pružanje usluga',
        dark: true,
        icon: 'mdi-account-switch',
        text: 'U skladu sa suvremenim pristupima oblikovanja\n' +
            'uspješnih sportskih sustava, u suradnji s brojnim pojedincima i organizacijama, pružamo najbolje\n' +
            'savjete i provodimo najbolje prakse na području strateškoga upravljanja, planiranja i komunikacija.',
      },
    ],
  }),
}
</script>

<style lang="sass">

</style>