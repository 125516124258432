<template>
  <div>
    <v-container fluid :style="{'background-color': item.color}">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" md="9" class="text-center">
            <v-img v-if="item.img === 'white'" height="50" class="mb-5" contain :src="require('@/assets/img/small_new_logo.png')"></v-img>
            <v-img v-else-if="item.img === 'bw'" height="50" class="mb-5" contain :src="require('@/assets/img/small_new_logo.png')"></v-img>
            <v-img v-else height="50" class="mb-5" contain :src="require('@/assets/img/small_new_logo.png')"></v-img>
            <div :class="item.img === 'white' ? 'white--text' : 'primary--text'" class="text-md-h5 text-uppercase">{{ item.title }}</div>
            <base-divider :color="item.divider_color" />
            <span :class="item.img !== 'bw' ? 'secondary--text': 'white--text'">{{item.text}}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </div>
  <!--  <v-theme-provider dark>-->
  <!--    <base-section-->
  <!--        id="social"-->
  <!--        class="primary text-center"-->
  <!--        space="56"-->
  <!--    >-->
  <!--      <base-icon class="mb-8">-->
  <!--        mdi-twitter-->
  <!--      </base-icon>-->

  <!--      <base-section-heading-->
  <!--          color="transparent"-->
  <!--          title="Strateško PLANIRANJE u sportu"-->
  <!--      >-->
  <!--        Strategije su temelj razvoja svake poslovne organizacije. Sportske organizacije, kao specifični dionici unutar društvenih zajednica, imaju zadatak strateški pristupati odnosima sa stakeholderima.-->
  <!--        Zahvaljujući strateškomu planiranju, sportska organizacija može odgovarati potrebama, željama i mišljenjima strateških javnosti. Planiranje podrazumijeva savjetovanje menadžmenta kako bi uskladio svoje interese s interesima svojih stakeholdera.-->
  <!--        U svrhu strateškoga planiranja koristimo istraživanja za prikupljanje korisnih podatka koji pomažu pri donošenju odluka u svezi s planiranjem, publicitetom i promocijom. Istraživanja, nadalje, pomažu u definiranju mogućih kriznih situacija koje prijete organizaciji. Istraživanje i planiranje prepoznaje prednosti korištenja odluka koje su nastale suradnjom kako bi se izgradili, održali ili popravili strateški odnosi. Određene funkcije mogu biti strateški temeljene na pozicioniranju kao odgovor na jedinstvenu dinamiku okoline u kojoj sportska organizacija djeluje. Neke su funkcije izravan rezultat izvanjskih zahtjeva.-->
  <!--        Agencija za sportski menadžment ProTomic sport pruža usluge izradbe strateških planova, tj. strategija za uspješno upravljanje sportskim organizacijama.      </base-section-heading>-->
  <!--    </base-section>-->
  <!--    123-->
  <!--  </v-theme-provider>-->
</template>

<script>
import BaseDivider from "@/layouts/Divider";

export default {
  components: {BaseDivider},
  name: 'SectionNews',
  props: {
    item: {
      type: Object,
    },
  },


  provide: {
    heading: {align: 'center'},
  },

  data() {
    return {
      bckColor: 'primary'
    }
  }
}
</script>
<div>

