<template>
  <v-container fluid style="background-color: #003D73">
      <v-container>
        <v-row justify="center">
          <v-col cols="10">
            <div
                class="white--text text-md-h6"
                :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
            >IMATE PITANJA?</div>


              <span class="white--text">
                Kontaktirajte nas, odgovor ćete dobiti u najkraćem mogućem roku.
              </span>
          </v-col>

          <v-col
              cols="12"
              md="2"
          >
            <v-btn

                color="white"
                href="mailto:protomicsport@gmail.com"
                large
                outlined
            >
              Kontaktirajte nas
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'Contact',
}
</script>
