<template>
  <div>
    <top-carousel />
<!--    <top-image />-->
    <about id="about" class="py-10" />
    <v-divider></v-divider>
    <blogs id="blog" />
    <services v-for="(item, i) in items" :key="i" :item="item" />
    <contact id="contact" />
    <services :item="item" />
    <academy />

  </div>
</template>

<script>
  // import TopImage from "@/layouts/TopImage";
  import About from "@/layouts/About";
  import Services from "@/layouts/Services";
  import Contact from "@/layouts/Contact";
  import Academy from "@/layouts/Academy";
  import TopCarousel from "@/layouts/TopCarousel";
  import Blogs from "@/layouts/Blogs";
  export default {
    name: 'Home',
    components: {Blogs, TopCarousel, Academy, Contact, About, Services},
    data: () => ({
      items: [
        {
          title: 'Strateško planiranje u sportu',
          img: 'white',
          color: '#003D73',
          divider_color: 'secondary',
          text: 'Strategije su temelj razvoja svake poslovne organizacije. Sportske organizacije, kao specifični dionici unutar društvenih zajednica, imaju zadatak strateški pristupati odnosima sa stakeholderima.\n' +
              'Zahvaljujući strateškomu planiranju, sportska organizacija može odgovarati potrebama, željama i mišljenjima strateških javnosti. Planiranje podrazumijeva savjetovanje menadžmenta kako bi uskladio svoje interese s interesima svojih stakeholdera. \n' +
              'U svrhu strateškoga planiranja koristimo istraživanja za prikupljanje korisnih podatka koji pomažu pri donošenju odluka u svezi s planiranjem, publicitetom i promocijom. Istraživanja, nadalje, pomažu u definiranju mogućih kriznih situacija koje prijete organizaciji. Istraživanje i planiranje prepoznaje prednosti korištenja odluka koje su nastale suradnjom kako bi se izgradili, održali ili popravili strateški odnosi. Određene funkcije mogu biti strateški temeljene na pozicioniranju kao odgovor na jedinstvenu dinamiku okoline u kojoj sportska organizacija djeluje. Neke su funkcije izravan rezultat izvanjskih zahtjeva.\n' +
              'Agencija za sportski menadžment ProTomic sport pruža usluge izradbe strateških planova, tj. strategija za uspješno upravljanje sportskim organizacijama.'
        },
        {
          title: 'Strateško savjetovanje u sportu',
          img: '',
          divider_color: 'primary',
          color: '#fff',
          text: 'Svijet se brzo mijenja, posebno nakon globalne pandemije Covid-19. Svi sportski klubovi u svijetu našli su se pred mnogobrojnim izazovima, a temeljni je izazov kako upravljati novonastalom situacijom, odnosno nastalim promjenama. Sportski menadžment treba odgovoriti na pitanje jesu li prepoznali jasan put i ispravno raspodijelili resurse u pravo vrijeme kako bi postigli svoje ciljeve. Održivo poslovanje u ovome specifičnom vremenu izgubilo je svaku alternativu. Agencija za sportski menadžment ProTomic sport sa svojim partnerima ima uvid u specifične trendove i načine rješavanja novih izazova. S našom mrežom specijaliziranih stručnjaka možemo rješavati sve aspekte strateškoga upravljanja i planiranja'
        },
        {
          title: 'Strateško komuniciranje u sportu',
          img: 'bw',
          divider_color: 'primary',
          color: '#939598',
          text: 'Sportske organizacije u stalnoj su komunikaciji sa svojom unutarnjom i vanjskom javnošću. Kako se mijenjaju društvo, odnosi, potrebe i prioriteti, tako se mijenja i komunikacija zajedno s promjenama komunikacijskih kanala i tehnika. Zbog toga je imperativ svakoga sportskog sustava strateški oblikovati svoju komunikaciju koja ima za cilj uspostaviti, održavati i unapređivati odnose sa strateškom javnošću. \n' +
              'Agencija za sportski menadžment ProTomic sport okuplja najeminentnije stručnjake u Hrvatskoj, okruženju i Europi, kako bi pružila savjete u svezi sa uspješnim oblikovanjem strateške komunikacije i uputila na optimalne modele komuniciranja s ciljem izgradnje uspješnih odnosa s ključnim dionicima i strateškim partnerima'
        }
      ],
      item: {
        title: 'Ekološki „ZELENI“ MENADŽMENT u sportu',
        img: '',
        divider_color: 'primary',
        color: '#fff',
        text: 'Svijet se sve više suočava s potrebom održivoga razvoja kao okvira za oblikovanje politika i strategija kontinuiranoga gospodarskog i socijalnog napretka, bez štete za okoliš, te prirodnih izvora koji su bitni za ljudske djelatnosti u budućnosti. Tako se postiže ravnoteža između uporabe, štednje i obnavljanja svih naših resursa te razumijevanje da će i generacije koje dolaze uvelike ovisiti o našemu današnjem djelovanju. \n' +
            'U skladu s mnogim regulativama donesenim u posljednjih nekoliko godina, i sportske su organizacije uočile potrebu izmjene dosadašnjega koncepta menadžmenta i uvođenja novoga, „zelenog“ menadžmenta koji će biti sposoban ostvariti i izgraditi ekološku svijest, očuvati okoliš i djelovati s povećanom odgovornošću prema životnoj sredini.\n' +
            'Agencija za sportski menadžment ProTomic sport pruža savjete za promociju ekoloških ciljeva, politiku nultoga zagađenja, izobrazbu, izradbu ekoloških izvješća, poštivanje zakonskih regulativa i sl. kojih se sportska organizacija treba pridržavati u primjeni „zelenoga“ menadžmenta.'
      },
    }),
  }
</script>

<style>
.footer-link{
  text-decoration: none;
}
</style>
