<template>
  <v-container fluid style="background-color: #939598">
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" class="text-center">
          <div class="text-center primary--text text-md-h5 text-uppercase">{{ item.title }}</div>
          <base-divider :color="item.divider_color" />
          <span class="text-center white--text">{{item.text}}</span>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <img class="imgShadow" :src="require('@/assets/img/home-protomic.png')" />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import BaseDivider from "@/layouts/Divider";

export default {
  name: "Academy",
  components: {BaseDivider},
  data() {
    return {
      item: {
        title: 'AKADEMIJA ProTomic sport',
        divider_color: 'primary',
        text: 'Znanje zastarijeva. Ono što danas znamo neće biti primjenjivo za deset, dvadeset i više godina. Svi pojedinci, sustavi i organizacije moraju stalno učiti i mijenjati se. Cjeloživotno obrazovanje neizostavan je proces stjecanja novih i konkurentnih znanja i vještina. \n' +
            'Akademija ProTomic sport specijalizirana je za izobrazbu na području strateškoga upravljanja, strateškog planiranja i strateškog komuniciranja (odnosi s javnošću, komunikacijske vještine i mediji). Provodimo programe izobrazbe u partnerstvu s obrazovnim institucijama i afirmiranim sveučilišnim profesorima. Naši su krajnji rezultati promjena, tržišno konkurentno i primjenjivo znanje i vještine.'
      }
    }
  }
}
</script>

<style scoped>
.imgShadow {
  max-height: 250px;
  max-width: 100%;
  box-shadow: 5px 10px 10px 0 rgba(0, 0, 0, 0.4), 5px 10px 20px 0 rgba(0, 0, 0, 0.2);
}
</style>