<template>
  <section>
    <v-carousel :height="minHeight" hide-delimiter-background
                hide-delimiters
                show-arrows-on-hover cycle>
      <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="require(`@/assets/img/${item.src}`)"
          gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
      >
        <v-container class="fill-height px-4" :class="mob ? 'mt-12 pt-12' : ''">
          <v-responsive
              class="d-flex align-center"
              height="100%"
              max-width="700"
              width="100%"
          >
            <!--          <base-heading title="ProTomic sport - Agencija za sportski menadžment"/>-->
            <component
                :is="tag"
                :class="classes"
                v-bind="$attrs"
                v-on="$listeners"
                class="hidden-xs-only"
            >
              <template>
                {{item.title}}
              </template>
            </component>
            <div class="hiddensm-and-up white--text font-weight-medium mb-2 text-h6">
              {{item.title}}
            </div>

            <div>
              {{item.text}}
            </div>

            <div
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
                class="d-flex flex-wrap"
            >
              <v-btn color="primary" v-if="item.alias" class="mt-7 hidden-xs-only" height="55" :to="'/blog/' + item.alias">
                Pročitaj više
              </v-btn>
              <v-btn color="primary" v-else class="mt-7 hidden-xs-only" @click="$vuetify.goTo('#about')">
                Pročitaj više
              </v-btn>
              <v-btn color="primary" v-if="item.alias" class="mt-3 hidden-sm-and-up" :to="'/blog/' + item.alias">
                Pročitaj više
              </v-btn>
              <v-btn color="primary" v-else class="mt-3 hidden-sm-and-up" @click="$vuetify.goTo('#about')">
                Pročitaj više
              </v-btn>
            </div>
          </v-responsive>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
export default {
  name: "TopCarousel",

  inject: {
    theme: {
      default: () => ({isDark: false}),
    },
    heading: {
      default: () => ({align: 'left'}),
    },
  },

  created() {
    this.mob = this.$vuetify.breakpoint.smAndUp
  },

  methods: {},

  provide: {
    theme: {isDark: true},
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

      return `calc(${height} - ${this.$vuetify.application.top}px)`
    },
    classes() {
      const classes = [
        this.fontSize,
        `font-weight-${this.weight}`,
        `mb-${this.space}`,
        `text-${this.align}`,
        this.theme.isDark && 'white--text',
      ]

      return classes
    },
    fontSize() {
      return this.$vuetify.breakpoint.width >= this.mobileBreakPoint
          ? this.size
          : this.mobileSize
    },
  },
  data() {
    return {
      mob: null,
      align: {
        type: String,
        default() {
          return this.heading.align
        },
      },
      size: 'display-2',
      space: 4,
      mobileSize: 'display-1',
      mobileBreakPoint: 768,
      tag: 'h1',
      weight: 'black',
      items: [
        {
          title: 'Menadžemnt i komunijacija u sportu',
          text: 'Knjiga Menadžment i komunikacija u sportu autora Ivana Tomića izišla je iz tiska u nakladi izdavačke kuće Synopsis iz Zagreba.',
          src: '6.jpg',
          alias: 'menadzment-i-komunikacija-u-sportu'
        },
        {
          title: 'ProTomic sport - Agencija za sportski menadžment',
          text: '„ProTomic sport“ je agencija za sportski menadžment. Nastala je kao odraz potrebe za pružanjem usluga strateškog oblikovanja sportskih organizacija.',
          src: '1.jpg',
        },
      ],
    }
  }
}
</script>

<style scoped>

</style>